body {
  margin: 0;
  font-family: 'Euclid Circular B', sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8FAFC !important;
  color: #262833 !important;
}

a {
  text-decoration: none !important;
  color: #262833 !important;
}

a:hover {
  color: #262833;
}

.LinkWrapper {
  display: block;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('./fonts/EuclidCircularB-Regular-WebTrial.woff') format('woff');
}

@font-face {
  font-family: 'Euclid Circular B bold';
  src: url('./fonts/EuclidCircularB-Bold-WebTrial.woff') format('woff');
}

h1 {
  font-family: 'Euclid Circular B bold', sans-serif;
  font-weight: 700;
}

td {
  border: 1px solid #E5E7EB !important;
  border-width: 1px 0px 0px 0px !important;
  padding: 10px;
}

table {
  border-collapse: collapse;
  border-radius: 0 0 25px 25px;
  background-color: white !important;
  caption-side: top;
}

th {
  border-width: 0 0px !important;
  color: #6B6D7C;
  font-weight: 200;
  font-size: 15px;
}

caption {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
  border: none;
  padding: 0 10px;
  margin: 0;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  caption-side: top;
}

.button {
  color: #fff !important;
  border: 0px !important;
  border-color: 0px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  padding: 8px 16px !important;
  transition: all 0.2s ease-in-out !important;
}

.blue {
  background-color: #4E87F6 !important;
}

.blue:hover {
  background-color: #1959F1 !important;

}

.profile {
  background-color: #283852 !important;
  color: #fff !important;
  border-color: #283852 !important;
}

.profile:hover {
  background-color: #212F45 !important;

}

.vote {
  background-color: #4E87F6 !important;
  padding: 8px 48px !important;

}

.vote:hover {
  background-color: #1959F1 !important;

}

.detail_data {
  background-color: #fff !important;
  border-radius: 25px;
}

.detail_image {
  border-radius: 0px;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.image-wrapper {
  border-radius: 0px;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.button-vote {
  color: #fff !important;
  border-color: #4E87F6 !important;
  border-radius: 50px !important;
  margin-top: 12px;
  padding: 12px 48px !important;
  font-size: 40px !important;
  transition: all 0.2s ease-in-out !important;
}

.image-wrapper img {
  transition: all 0.3s ease-out;
}

.image-wrapper img.winner {
  animation: blink 0.3s ease-out forwards;
}

@keyframes fallOffScreen {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 0.1;
  }

  60% {
    opacity: 1;
  }

  80% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes byebye {
  0% {
    transform: rotate(0deg) scale(1) translate(0, 0);
  }

  25% {
    transform: rotate(180deg) scale(0.8) translate(50px, 50px);
  }

  50% {
    transform: rotate(360deg) scale(0.6) translate(100px, 0);
  }

  75% {
    transform: rotate(540deg) scale(0.4) translate(50px, -50px);
  }

  100% {
    transform: rotate(720deg) scale(0) translate(0, 0);
  }
}

.image-wrapper img.loser {
  animation: byebye 0.3s ease-out forwards;
}

.voting-container {
  position: relative;
}

.vote-btn {
  cursor: pointer;
}

.xp-text {
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: bold;
  color: #28A745;
  opacity: 0;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
}

.vs-image {
  position: relative;
  top: -40px;
  transform: translateX(-10%);
  width: 120%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.vs-text-wrapper {
  display: flex;
  justify-content: center;
}

.xp-text.fade {
  opacity: 1;
  animation: fadeAnimation 1s ease-out;
}

@keyframes fadeAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.vs-container {
  position: relative;
  display: inline-block;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-vote-home {
  color: #fff !important;
  border-radius: 50px !important;
  padding: 2px 12px !important;
  font-size: 16px !important;
  transition: all 0.2s ease-in-out !important;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.hover-hand:hover {
  cursor: pointer;
}

.vote-count-text {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
}

.button-container {
  margin-bottom: 0;
}

.caption-container {
  margin-bottom: -10px;
}

.alpha-text {
  position: relative;
  bottom: 10px;
  transform: translateY(95%);
  margin-left: 5px;
}
.sign-in-button {
  white-space: nowrap;
  margin-left: 10px;
  align-self: center;
  margin-top:-30px;
}

.leaderboard-link {

  margin-top:-30px;
}

@media (max-width: 768px) {
  .xp-text {
    font-size: 16px;
  }

  .detail_image {
    border-radius: 0px;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;

  }

  .vs-image {
    transform: translateX(-20%);
    width: 180%;
  }

  .alpha-text {
    position: relative;
    transform: translateY(83%);
    margin-left: 5px;
    bottom: 10px;
  }

  .button-vote-text {
    font-size: 12px !important;
  }
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-responsive {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.leaderboard-tabs {
  border: none !important;
}
.leaderboard-tabs .nav-link {
  border: none !important;

}
